<template>
  <div>
    <el-dialog
      class="AddDialog"
      title="订单详情"
      :visible.sync="dialogVisible"
      width="800px"
      hegiht="1000px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-table border ref="dataTable" :data="shoporder">
        <el-table-column label="序号" align="center">
          <template slot-scope="scope">
            <span>{{ (page - 1) * limit + scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="box_name"
          align="center"
          label="设备租赁号"
        ></el-table-column>
        <el-table-column prop="relet_end_time" align="center" label="续租周期"
          ><template slot-scope="scope">
            <span> {{ (scope.row.relet_end_time * 1000) | Time }}</span>
          </template></el-table-column
        >

        <el-table-column
          prop="price"
          label="支付金额"
          align="center"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { reletOrderInfo } from "@/request/api";
import pageTable from "@/components/pageTable.vue";

export default {
  name: "AddDialog",
  components: {
    pageTable,
  },
  data() {
    return {
      list_id: "",
      count: "",
      dialogVisible: false,
      shoporder: [],
      limit: "10",
      page: 1,
    };
  },
  filters: {
    Time(e) {
      //处理时间
      return moment(parseInt(e)).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    getUserList() {
      let params = {
        token: sessionStorage.getItem("token"),

        list_id: this.list_id,
      };
      reletOrderInfo(params).then((res) => {
        this.count = res.data.count;
        this.shoporder = res.data.data;
      });
    },

    show(row) {
      this.dialogVisible = true;
      this.list_id = row.list_id;
      this.getUserList();
    },

    close() {
      this.dialogVisible = false;
      this.list_id = "";
      this.shoporder = [];
    },
  },
};
</script>

<style>
.block {
  margin-top: 20px;
  margin-left: 900px;
}
</style>
